<template>
  <v-container fill-height justify="center" align="center">
    <v-row justify="center" align="center">
      <v-col md="8">
        <v-card
          elevation="4"
          :scrollable="$vuetify.breakpoint.xsOnly"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card-title class="pa-0">
            <v-toolbar dark flat>
              <v-toolbar-title>COMPROBANTE DE EGRESO</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pl-6">
            <v-row>
              <v-col cols="12">
                <v-toolbar rounded flat>
                  <v-toolbar-title>Recibí de:</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    fab
                    dark
                    x-small
                    color="red"
                    @click="clientsLst = !clientsLst"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-toolbar>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="this.client.name"
                      :rules="f_required"
                      label="Nombre"
                      hide-details="auto"
                      required
                      dense
                      class="mt-3"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="this.client.mobile"
                      :rules="f_required"
                      label="Celular"
                      hide-details="auto"
                      required
                      dense
                      class="mt-3"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="this.client.address"
                      :rules="f_required"
                      label="DIrección"
                      hide-details="auto"
                      required
                      dense
                      class="mt-3"
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-toolbar rounded flat>
              <v-toolbar-title>Por concepto de</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                fab
                dark
                x-small
                color="red"
                @click="concepts_dialog = !concepts_dialog"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="concepts"
              :items-per-page="10"
              mobile-breakpoint="0"
              item-key="id"
              sort-by="name"
              hide-default-footer
              :loading="loading_status"
              loader-height="10"
              loading-text="Cargando ..."
            >
              <template #top> </template>
              <template v-slot:[`item.item_concept`]="{ item }">
                {{ item.item_concept }} <br />
                {{ item.item_note }} <br />
                <small>
                  {{ "REF: " + item.item_reference }}
                </small>
              </template>
              <template v-slot:[`item.item_value`]="{ item }">
                {{ "$ " + parseFloat(item.item_value).toLocaleString(2) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteItem(item)"> mdi-close </v-icon>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-text>
            <v-toolbar flat>
              <v-toolbar-title>Formas de pago</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                fab
                dark
                x-small
                color="red"
                @click="payment_dialogo = !payment_dialogo"
                :disabled="!this.totals.unpaid > 0"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-toolbar>

            <v-list dense>
              <v-list-item v-for="(item, index) in payments" :key="index">
                <v-list-item-content dark class="pa-0 ma-0">
                  <v-list-item-title v-text="item.payment_concept" />
                </v-list-item-content>
                <v-list-item-action
                  v-text="'$ ' + item.payment_value"
                  class="pa-0 ma-0"
                />
                <v-list-item-icon>
                  <v-icon @click="delete_payment(index)">mdi-close</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow dark>
              <v-btn @click="sing_dialogo = !sing_dialogo">
                <span>Firmar</span>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :disabled="this.totals.unpaid > 0"
                @click="save_doc"
              >
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="concepts_dialog"
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form ref="conceptForm" v-model="validConcept" @submit="add2concepts">
        <v-card>
          <v-card-title>
            Por concepto de
            <v-spacer></v-spacer>
            <v-icon @click="concepts_dialog = !concepts_dialog">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-combobox
                  v-model="concept.item_concept"
                  :items="concept_lst"
                  :rules="f_required"
                  hide-details
                  label="Concepto"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="concept.item_note"
                  label="Nota / Observación"
                  hide-details
                  :rules="f_required"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="concept.item_reference"
                  label="Documento Referencia"
                  :rules="f_required"
                  hide-details
                  outlined
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="concept.item_value"
                  label="Valor"
                  placeholder="Valor"
                  type="number"
                  class="right-input"
                  clearable
                  :rules="f_required"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="success" type="submit">Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="payment_dialogo" max-width="600px">
      <v-form v-model="payvalid" ref="payform" @submit="addPayment">
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>Registrar Pagos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="payment_dialogo = !payment_dialogo">
              mdi-close
            </v-icon>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-combobox
                  v-model="payment.payment_concept"
                  :items="FPLST"
                  :rules="f_required"
                  hide-details
                  label="Forma de pago"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="payment.payment_reference"
                  label="Documento / Referencia"
                  :error="payRefe"
                  @keyup="updateRefe"
                  required
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="payment.payment_value"
                  label="Valor"
                  type="number"
                  class="right-input"
                  :error="payValue"
                  clearable
                  :rule="payRule"
                  outlined
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!payvalid"
              color="success"
              class="mr-4"
              type="submit"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-navigation-drawer
      right
      class="navigation-drawer"
      :value="showPrint"
      width="400px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      app
    >
      <v-card height="100vh">
        <v-toolbar dark>
          <h3>Comprobante Ingreso</h3>
          <v-spacer></v-spacer>
          <v-btn fab dark @click="showPrint = !showPrint">
            <v-icon x-large> mdi-close-circle-outline </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img
            height="100%"
            width="100%"
            class="white"
            :src="invoiceImg"
          ></v-img>
        </v-card-text>
      </v-card>
      <v-toolbar class="fixed-footer">
        <v-spacer></v-spacer>
        <v-btn color="success" x-large> Imprimir </v-btn>
      </v-toolbar>
    </v-navigation-drawer>

    <v-dialog v-model="sing_dialogo" width="420">
      <v-card>
        <v-card-title> Firma </v-card-title>
        <v-card-text>
          <VueSignaturePad
            class="sign"
            width="420"
            height="120px"
            ref="signaturePad"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn>Limpiar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="clientsLst"
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card tile>
        <v-card-title dark>
          Contactos
          <v-spacer></v-spacer>
          <v-icon @click="clientsLst = !clientsLst">mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="client_header"
            :items="data_list"
            :items-per-page="10"
            item-key="code"
            sort-by="name"
            mobile-breakpoint="10"
            class="table-cursor mt-3"
            :loading="loading_status"
            loader-height="10"
            loading-text="Cargando ..."
          >
            <template v-slot:[`item.name`]="{ item }">
              <div @click="handleClick(item)">
                {{ item.name }} <br />
                <small>
                  {{ item.email }}
                </small>
              </div>
            </template>
            <template v-slot:[`item.mobile`]="{ item }">
              {{ item.mobile }}
              <v-icon @click="editClient(item)">
                mdi-square-edit-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            v-model="client_text"
            ref="client_text"
            label="Buscar Contacto"
            prepend-icon="mdi-plus"
            :error="errorSearch"
            :error-messages="searchMessage"
            append-icon="mdi-account-search"
            @click:append="get_client"
            @click:prepend="newClient"
            outlined
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PartyForm
      table="clients"
      :item="client"
      :dialog="client_dialog"
      :newItem="newItem"
      @close="client_dialog = !client_dialog"
      @refresh="refresh()"
    />
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";

import createParty from "../utils/createParty.js";
import createPayment from "../utils/createPayment.js";
import create_item from "../utils/create_item.js";
import PartyForm from "../components/PartyForm.vue";

export default {
  components: { PartyForm },
  data() {
    return {
      options: "",
      sing_dialogo: false,
      errorSearch: false,
      searchMessage: "",
      clientsLst: false,
      client_dialog: false,
      client_text: "",
      data_list: [],
      newItem: false,
      payment_dialogo: false,
      concepts_dialog: false,
      loading_status: false,
      validConcept: false,
      payvalid: false,
      showPrint: false,
      invoiceImg: null,
      signature: null,
      payment: createPayment(),
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      headers: [
        {
          text: "Concepto",
          align: "start",
          sortable: true,
          value: "item_concept",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "item_value",
          dataType: "number",
        },
        {
          text: "",
          value: "actions",
          align: "end",
          width: 50,
          sortable: false,
        },
      ],
      company: createParty(),
      concept: create_item(),
      client: createParty(),
      concepts: [],
      concept_lst: [],
      FPLST: ["EFECTIVO", "TARJETA", "CXP"],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        code: "01",
        value: "EFECTIVO",
      },
      FP: null,
      signatureStock: null,
      f_required: [(v) => !!v || "! Campo requerido !"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      client_header: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },
        {
          text: "Celular",
          align: "end",
          sortable: true,
          value: "mobile",
        },
      ],
    };
  },
  mounted() {
    this.company = window.company;
    // this.concept_lst = window.settings["INGRESOS"];
    // this.FP = window.settings.FP;
  },
  methods: {
    refresh() {
      this.client_dialog = false;
      this.get_client();
    },
    onBegin() {
      console.log("=== Begin ===");
    },
    onEnd() {
      console.log("=== End ===");
    },

    editClient(e) {
      this.client = { ...e };
      this.client_dialog = true;
    },
    newClient() {
      this.client = createParty();
      this.client_dialog = true;
    },
    add2concepts(e) {
      e.preventDefault();
      this.$refs.conceptForm.validate();
      if (this.validConcept) {
        const newItem = { ...this.concept };
        newItem.item_value = parseFloat(newItem.item_value);
        newItem.price = parseFloat(newItem.item_value);

        this.concepts.push(newItem);
        this.updateTotal();
        this.concept = create_item();
        this.concepts_dialog = false;
      }
    },
    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      console.log(this.concepts);
      this.concepts.forEach((itm) => {
        this.totals.qty += parseFloat(itm.quantity);
        this.totals.total += parseFloat(itm.quantity) * parseFloat(itm.price);
      });
      this.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;
      console.log(this.payment);
      if (this.totals.unpaid === 0) {
        this.payment_dialogo = false;
        this.topay = true;
        this.save_receipt();
      } else {
        this.topay = false;
      }
    },
    get_client() {
      var tosearch = this.$refs.client_text.value;
      if (tosearch !== "" && tosearch.length >= 6) {
        this.searchMessage = "";
        this.errorSearch = false;
        var qry = {
          account: this.$store.state.profile.account,
          table: "clients",
          filters: [],
        };
        if (tosearch !== "") {
          qry.filters.push({
            field: "name",
            operator: " like",
            value: "%" + tosearch + "%",
          });
        }
        this.loading_status = true;
        webserver("get_table", qry, (data) => {
          this.loading_status = false;
          this.data_list = data;
        });
      } else {
        this.searchMessage = "Compo requerido, mínimo 6 caracteres.";
        this.errorSearch = true;
      }
    },
    handleClick(e) {
      this.client = e;
      this.clientsLst = false;
    },
    addPayment(e) {
      e.preventDefault();
      if (this.pay_mode) {
        if (this.payment.payment_concept === "EFECTIVO") {
          this.payments.push({ ...this.payment });
          this.updateTotal();
          if (this.totals.unpaid < 0) {
            var cambio = {
              payment_concept: "EFECTIVO",
              payment_value: this.totals.unpaid,
            };
            this.payments.push({ ...cambio });
            this.updateTotal();
          }
        } else {
          if (this.payment.payment_value <= this.totals.unpaid) {
            this.payments.push({ ...this.payment });
            this.updateTotal();
          } else {
            this.payValue = true;
            this.payment.payment_value = 0;
          }
        }
      }
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    delete_payment(e) {
      this.payments.splice(e, 1);
      this.updateTotal();
    },
    deleteItem(e) {
      this.concepts.splice(e, 1);
      this.updateTotal();
    },
    save_doc() {
      var qry = {
        doc: {
          doc_type: "CS-IN",
          doc_value: this.totals.paid,
          account: this.$store.state.profile.account,
          userId: window.profile.email,
          paidTo: this.client,
        },
        items: this.concepts,
        payments: this.payments,
      };
      console.log(qry);
      // webserver("receipt_save", qry, (data) => {
      //   this.resetDoc();
      //   this.loading_vendors = false;
      //   console.log(data);
      //   this.print_doc(data);
      // });
    },
    resetDoc() {
      this.payments = [];
      this.concepts = [];
      this.client = createParty();
    },
    print_doc(data) {
      var LS = 50;
      var itemsQty = data.items.length * LS * 3;
      const elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 1800 + itemsQty;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      const ctx = elem.getContext("2d");

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(window.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(window.seller.PartyName, center, line);
      line += LS;
      ctx.fillText("Nit : " + window.seller.PartyIdentification, center, line);

      line += LS;
      ctx.fillText(
        window.seller.AddressLine + ", " + window.seller.CityName,
        center,
        line
      );
      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText("RECIBO DE CAJA", center, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText("No. " + data.document.doc_number, center, line);
      line += LS;
      ctx.fillText(data.document.issueDate, center, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("C.C./Nit : " + data.buyer.PartyIdentification, left, line);
      line += LS;
      ctx.fillText("Nombre    : " + data.buyer.PartyName, left, line);
      line += LS;
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Concepto", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      var tdiscount = 0;

      data.items.forEach((row) => {
        var price = row.price * row.quantity;
        var discount = row.discount * row.quantity;
        ctx.textAlign = "start";
        ctx.fillText(row.item_concept, left, line);
        line += LS;
        ctx.textAlign = "start";
        ctx.fillText(row.item_note, left, line);
        ctx.textAlign = "end";
        ctx.fillText(price.toLocaleString(), right, line);
        line += LS;
        ctx.textAlign = "start";
        ctx.fillText(row.item_reference, left, line);

        line += LS;
        total = total + price;
        tdiscount = tdiscount + discount;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      line += LS;

      ctx.textAlign = "center";
      ctx.fillText("Formas de pago", center, line);
      line += LS;

      data.payment.forEach((item) => {
        item.valor = parseInt(item.valor);
      });
      console.log(data);
      data.payment.forEach((row) => {
        ctx.textAlign = "start";
        ctx.fillText(
          row.payment_concept + " - " + row.payment_reference,
          left,
          line
        );
        ctx.textAlign = "end";
        ctx.fillText(row.payment_value.toLocaleString(), right, line);
        line += LS;
      });
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
      // var a = window.open("", "", "height=600, width=400");
      // a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      // a.print();
      // a.document.close();
      // setTimeout(() => {
      //   a.close();
      // }, 500);
    },
  },
};
</script>

<style>
.sign {
  border: darkgray solid 1px;
}
</style>
